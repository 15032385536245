#footer{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #00888f;
    color: #f7f7f7;
    position: absolute;
    padding: 0.25vh;
    bottom: 0;
    left: 0;
    border-top: 0.15rem solid #f7f7f7;
}
#footer p{
    font-family: 'Lobster', cursive;
    font-size: 2vh;
    cursor: default;
    display: inline-block;
    margin:  0.5vh 1vw;
}
#footer a{
    color: #f7f7f7;
    text-decoration: none;
    transition: filter 0.25s;
}
#footer a:hover{
    filter: brightness(0.8);
}
.icon{
    position: relative;
    top:-0.3vh;
}

@media screen and (max-aspect-ratio: 9/10) {
    #footer{
        width: 100%;
        height: 4vh;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background-color: #00888f;
        color: #f7f7f7;
        position: absolute;
        padding: 0.25vh;
        bottom: 0;
        text-align: center;
    }    
    #footer p{
        font-family: 'Lobster', cursive;
        font-size: 2.75vw;
        cursor: default;
        display: inline-block;
        margin:  0.5vh 1vw;
    }
    #footer a{
        color: #f7f7f7;
        text-decoration: none;
        transition: filter 0.25s;
    }

}