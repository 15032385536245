#homeContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height:92vh;
    max-width: 1200px;
    margin: 0 auto;
}
.home-background {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2rem;
}

.header-row {
    text-align: center;
    margin-bottom: 50px;
}

.header {
    background-color: #00888f;
    color: #ffffff;
    padding: 2rem 1rem 2rem;
    border-radius: 10px;
    box-sizing: content-box;
}

.primary-text {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.secondary-text {
    font-size: 1.25rem;
    margin-bottom: 20px;
    }

.learn-more-button {
    background-color: #ff6f61;
    border-color: #ff6f61;
    color: #ffffff;
    font-weight: bold;
    transition: filter 0.25s, color 0.25s, background-color 0.25s;
}

.learn-more-button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #ff6f61;
}

.learn-more-button:active {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #ff6f61 !important;
    filter: brightness(0.8);
}

.content-row {
    text-align: center;
}

.card {
    background-color: #faf9f1;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-title {
    color: #00888f;
    margin-bottom: 15px;
    font-family: 'Lobster', cursive;
}

.card-text {
    color: #333333;
}

.link-button {
    color: #ff6f61;
    font-weight: 700 !important;
}

.link-button:hover {
    color: #00888f;
}

.elevateIcon{
    position: relative;
    top: -0.25rem;
    display: inline;
}


@media screen and (max-aspect-ratio: 9/10) {
    #homeContainer {
        height: auto;
        justify-content: flex-start;
        padding: 1rem;
        margin-bottom: 2rem;
    }

    .home-background {
        padding: 1rem;
    }

    .header {
        padding: 1.5rem 0.5rem;
        margin: 0 1rem;
    }

    .primary-text {
        font-size: 2rem;
    }

    .secondary-text {
        font-size: 1rem;
    }

    .learn-more-button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .content-row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 100%;
        margin: 1rem 0;
    }

    .card-title {
        font-size: 1.25rem;
    }

    .card-text {
        font-size: 1rem;
    }

    .link-button {
        font-size: 1rem;
    }
}
