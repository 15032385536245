
#navBar {
    width: 100%;
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #00888f;
    color: #f7f7f7;
    padding-top: 1.5vh;
}
#dropdown {
    position: absolute;
    left: 1.5vh;
    top: 1.5vh;
    display: flex;
    flex-direction: row;
}
#dropdown-basic {
    color: #00888f;
    background-color: #f7f7f7;
    font-weight: bold;
    outline: none;
    border: none;
    font-size: 2.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 3vh;
    padding: 0 0.25vh 0;
    transition: transform 0.3s ease-in-out, background-color 0.3s, color 0.3s;
    border-radius: 0.25rem;
}
.dropdown-toggle::after {
    content: none;
}
#dropdown-basic.show,
#dropdown-basic:focus,
#dropdown-basic:hover {
    background-color: #ff6f61;
    color: #f7f7f7;
    border-color: #ff6f61;
}
.dropdown-item{
    font-weight: bolder;
    color: #00888f;
    transition: color 0.15s, background-color 0.15s;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    font-size: 2vh;
}
.dropdown-item:hover {
    background-color: #ff6f61 !important; /* Replace with your color */
    color: #f7f7f7 !important; /* Optional: change text color on hover */
}
.dropdown-item:active {
    background-color: #00888f !important; /* Optional: change color when item is active */
}
h5{
    padding-left: 1vh;
    font-weight: bold;
    cursor: pointer;
}
.rotated {
    transform: rotate(180deg);
  }
img{
    position: absolute;
    height: 4vh;
    right: 1vh;
    top:1vh
}
#nameTitle{
    font-weight: bold;
    font-family: 'Lobster', cursive;
    font-size: 3vh;
    color:#ff6f61;
    cursor: default;
    text-shadow: 
        1px 1px 0 #f7f7f7,
        -1px 1px 0 #f7f7f7,
        1px -1px 0 #f7f7f7,
        -1px -1px 0 #f7f7f7,
        1px 0 0 #f7f7f7,
        -1px 0 0 #f7f7f7,
        0 1px 0 #f7f7f7,
        0 -1px 0 #f7f7f7,
        2px 2px 0 #f7f7f7,
        -2px 2px 0 #f7f7f7,
        2px -2px 0 #f7f7f7,
        -2px -2px 0 #f7f7f7,
        2px 0 0 #f7f7f7,
        -2px 0 0 #f7f7f7,
        0 2px 0 #f7f7f7,
        0 -2px 0 #f7f7f7;
}
.preIcon{
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    top:0.5vh;
}
.active-item {
    background-color: #f1c40f !important; /* Background color for the active item */
    color: #ffffff !important; /* Text color for the active item */
}


@media screen and (max-aspect-ratio: 8/10) {
    #navBar {
        height: 10vh;
        padding: 1rem;
        flex-direction: row;
        align-items: center;
    }

    #dropdown {
        position: static;
    }

    #dropdown-basic {
        font-size: 1.5rem;
        padding: 0.5rem 0.5rem 0.5rem;
        box-sizing: content-box;
        margin: auto;
    }

    .dropdown-item {
        font-size:1.25rem;
        min-width: fit-content;
        display: block;
    }

    h5 {
        font-size: 1.5rem;
        padding-left: 0;
        text-align: center;
        margin: auto;
    }

    img {
        display: none;
    }

    #nameTitle {
        font-size: 1.5rem;
        text-align: right;
    }

    .preIcon {
        top: 0;
        margin-right: 1rem;
    }
    #menuTitle{
        display: none;
    }
}
