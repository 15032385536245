/* Handle */
#jobContainer::-webkit-scrollbar-thumb {
    background-color: #2dafb6 ; 
}

#experienceContainer{
    position: relative;
    height:100%;
    overflow: auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 3vh calc(50vw - 450px);
}

#experience{
    width: 100%;
}

#summary{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#summary p{
    text-align: center;
}

#summary div{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

#summary p{
    text-align: justify;
}

#jobContainer{
    display: block;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    border: 0.2rem solid #00888f;
    border-radius: 0.5rem;
    background-color: #f79b93;
}

#smallSections{
    width: 100%;
    margin: 0 auto;
}

#experienceContainer ul{
    list-style-type: none;
}

#experienceContainer ul li{
    margin: 1rem 0;
}

#experienceContainer ul a{
    color: #00888f !important;
    background-color: #f3feff;
    padding: 0.25rem 0.5rem;
    border-radius: .15rem;
    text-decoration: none;
}

section {
    position: relative;
    width: 90%;
    background-color: transparent;
    padding: 0.5rem ;
    color: #00888f;
    font-weight: 700;
    height: fit-content;
}

section p{
    padding: 0;
    margin: 0.25rem;
}

.myPic{
    position: relative;
    height: 12.5vh;
    width: 12.5vh;
    border-radius: 0.5rem;
    float: left;
    margin: 0 1rem 1rem;
    box-shadow: 0 0 0 0.25rem #faf9f1, 0 0 0 0.5rem #00888f;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.njit{
    position: relative;
    height: 6vh;
    float: right;
    margin: 0 1rem 1rem;
}

section h2 {
    color: #00888f;
    border-bottom: 2px solid #ff6f61;
    padding-bottom: 0.5rem;
    font-family: 'Lobster', cursive;
}

.job{
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 1rem 1rem 1.5rem;
    background-color: #faf9f1;
    z-index: 1;
}

.job:nth-child(2n){
    background-color: #d5f8fa;
}

.job h3 {
    color: #f3feff;
    background-color: #00888f;
    text-align: left;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    position: relative;
}

.job p {
    color: #f3feff;
    font-weight: 600;
    width: fit-content;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    font-style: italic;
    background-color: #2dafb6;
    
}

.job ul {
    list-style-type: disc;
    padding-left: 0.25rem 1rem;
}

.flexer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.flexer div{
    border: 0.15rem solid #00888f;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #00888f;
    color: #f3feff;
}

.job h3 img{
    float: right;
    position: absolute;
    padding: none;
    margin: 0 ;
    height: 90%;
    top: 5%;
    right: 0.25rem;
}

@media screen and (max-aspect-ratio: 9/10) {
    #summary div{
        flex-direction: column;
        justify-content: center;
    }
    .myPic{
        display: block;
        width: 25vw;
        height: 25vw;
        margin: 0 auto 1.5rem;
        border-radius: 50%;
    }
    #experienceContainer{
        flex-direction: column;
        margin-bottom: 2rem;
        height: auto;;
    }

    #jobContainer{
        max-height: none;
    }
    
    #smallSections{
        width: 100%;
    }

    .job h3 {
        font-size: 100%;
        padding: 0.25rem;
        text-align: center;
    }
    
    .job p {
        font-size: 90%;
        margin: 0;
    }
    
    .job ul {
        list-style-type: disc;
        padding-left: 0.25rem 1rem;
    }
    
    .flexer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .job h3 img{
        float: right;
        position: absolute;
        padding: none;
        margin: 0 ;
        height: 90%;
        top: 5%;
        right: 0.25rem;
    }
    .flexer{
        flex-direction: column;
    }
}