#videoContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#videoContainer h2{
    text-align: left;
    font-family: 'Lobster', cursive;
    color: #00888f;
}
.video-player-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    margin: auto;
    background-color: #faf9f1;
    box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
}

#actualPlayer{
    overflow: hidden;
    border-radius: 1rem;
}

.video-player {
    flex: 3;
    margin-right: 20px;
}

.video-player h2 {
    margin-top: 0;
    font-size: 1.5em;
    margin-bottom: 10px;
}

video {
    width: 100%;
    border-radius: 8px;
}

.video-list {
    flex: 1;
    max-height: 500px;
    overflow-y: auto;
}

.video-list h2 {
    margin-top: 0;
    font-size: 1.2em;
}

.video-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.video-list li {
    padding: 0.75rem;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    border-radius: 0.5rem;
    font-weight: bold;
}

.video-list li:hover {
    background-color: #2dafb6 !important;
    color: white;
    font-weight: bold;
}

.video-list li:last-child {
    border-bottom: none;
}

.video-list li.active {
    background-color: #00888f;
    color: #fff;
    font-weight: bold;
}

@media screen and (max-aspect-ratio: 8/10) {
    #videoContainer{
        display: block;
    }
    .video-player-container {
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .video-player {
        flex: 1;
        margin-right: 0;
    }
    #actualPlayer{
        height: 30vh;
        border-radius: 0.5rem;
    }
    #videoContainer h2{
        text-align: center;
        font-size: 2srem,;
    }
    .video-list {
        flex: 1;
        width: 100%;
        max-height: none;
        overflow-y: auto;
    }
    .video-list h2 {
        padding-top: 1.5rem;
        font-size: 1.5em;
        border-top: 1px solid #ccc;
    }
}