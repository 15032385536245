@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
/* width */
::-webkit-scrollbar {
    width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #ff6f61; 
    cursor: pointer;
}
.App{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
#Nav{
    z-index: 2;
}

header{
    border-bottom: 0.15rem solid #f7f7f7;
}

#Home{
    z-index: 1;
}

main{
    position: relative;
    display: block;
    position: relative;
    height: 92vh;
    overflow-y: auto;
}

main::-webkit-scrollbar-thumb {
    background-color: #ff6f61;
}
.fade-in {
    position: relative;
    opacity: 0;
    top: -1rem;
    filter: grayscale(100%);
    animation: fadeInAnimation 0.75s ease-in-out forwards;
}

.back-to-top {
    display: none;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        top: -1rem;
        filter: grayscale(100%);
    }
    50%{
        filter: grayscale(100%);
    }
    100% {
        opacity: 1;
        top: 0;
        filter: grayscale(0%);
    }
}
@media screen and (max-aspect-ratio: 8/10) {
    main{
        height: calc(100vh - 14vh);
        padding-bottom: 5vh;
    }
    .back-to-top {
        position: fixed; /* Ensures the button is fixed relative to the viewport */
        bottom: 5vh;    /* Distance from the bottom of the viewport */
        right: 3.5vw;     /* Distance from the right side of the viewport */
        z-index: 99;     /* Ensure the button is above other elements */
        border: none;
        outline: none;
        background-color: #00888f;
        color: #f7f7f7;
        cursor: pointer;
        padding: 1.25vh;
        border-radius: 50%;
        font-size: 2.5vh;
        box-shadow: 0 0 0 0.15rem #f7f7f7, 0 0 0 0.3rem #00888f;
        display: flex;
        justify-content: center;
        animation: fadeInBackToTop 0.5s ease-in-out;
      }
}
@keyframes fadeInBackToTop {
    0% {
        opacity: 0;
        bottom: 6vh;
        filter: grayscale(100%);
    }
    50%{
        filter: grayscale(100%);
    }
    100% {
        opacity: 1;
        bottom: 5vh;
        filter: grayscale(0%);
    }
}