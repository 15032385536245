.pnf{
    height: 100%;
    width: 100%;
    align-content: center;
    text-align: center;
    color: #00888f;
}

.pnf h2{
    font-size: 4rem;
    font-weight: bold;
}

.pnf p{
    color: #ff6f61;
    font-weight: bold;
    font-size: 1.5rem;
}

.pnf-link{
    font-size: 3rem !important;
    color: #f7f7f7;
    background-color: #00888f;
    padding: 0rem 1rem 1rem;
    border-radius: 50%;
    transition: box-shadow 0.25s;
    box-shadow: none;
}

.pnf-link:hover{
    box-shadow: 0 0 0 0.5rem #2dafb6;
}

.pnf-link:active{
    box-shadow: 0 0 0 0.35rem #2dafb6;
}