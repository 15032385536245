#projectcotnainer{
    width: 100%;
    margin: auto;
    max-height: 90vh;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
    gap: 20px; /* Gap between grid items */
    padding: 1.5rem 1rem;
}

.cornerLogo{
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    height: 7.5vh !important;
    width: fit-content !important;
    background-color: transparent !important;
    box-shadow: none  !important;
}

#projectcotnainer::-webkit-scrollbar-thumb {
    background-color: #ff6f61; 
}

.project-item{
    background-color: #faf9f1;
    padding: 20px 20px 2rem;
    text-align: center;
    min-height: 15vw;
    box-shadow: 0 0 0.5rem 0.25rem darkgray;
    margin: 0 0.25rem;
    border-radius: 0.5rem;
    transition: filter 0.25s;
    color: #00888f;
    cursor: default;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.5s;
}

.project-item:hover{
    box-shadow:0 0 0.5rem 0.25rem #f1c40f;
}

.project-item:hover img{
    background-color: #ffeeac;
    box-shadow: 0 0 1rem 0.25rem #f1c40f inset;
}

.project-item img{
    width: 7.5vw;
    height: 7.5vw;
    position: relative;
    margin: auto;
    border-radius: 0.5rem;
    padding: 0.75rem;
    background-color: #d5f8fa;
    box-shadow: 0 0 0.5rem 0.25rem #00888f inset;
    transition: box-shadow 0.5s, background-color 0.5s;
}

.project-item h2{
    padding:  2vh 1vw;
    font-family: 'Lobster', cursive;
    color: #00888f;
    border-bottom: 1px solid #00888f;
}

.project-item h4{
    padding-top: 1rem;
    text-decoration: underline;
}

.project-item p{
    text-align: justify;
    padding-top: 1rem;
}

.availability{
    display: flex;
    justify-content: space-around;
    margin: 0;
    background-color: #d5f8fa;
    padding: 0.25rem;
    border-radius: 0.5rem;
    transition: background-color 0.5s;
}

.availability p{
    text-align: center;
    font-weight: bolder;
    margin: 0;
    padding: 0;
}

.availability h6{
    background-color: #00888f;
    color: #faf9f1;
    padding: 0.25rem;
    border-radius: 0.25rem;
}

.project-item:hover .availability{
    background-color: #ffeeac; 
}

.btn-primary{
    background-color: #00888f;
    font-weight: bold;
    outline: #00888f;
    border-color: #00888f;
    border-radius: 0;
    transition: filter 0.25s, background-color 0.25s;
}

.btn-primary:hover{
    background-color: #00888f;
    outline: #00888f;
    border-color: #00888f;
    filter: brightness(0.9);
}

.btn-primary:focus{
    background-color: #f1c40f !important;
    outline: #f1c40f !important;
    border-color: #f1c40f !important;
    filter: brightness(1);
}

.btn-primary:disabled{
    background-color: #ff6f61;
    outline: #ff6f61;
    border-color: #ff6f61;
    opacity: 1;
}

.visitButton{
    width: 100%;
}

.visitButton.disabled{
    filter: brightness(0.75);
    pointer-events: none;
    background-color: #00888f;
    outline: #00888f;
    border-color: #00888f;
}

.unavailable{
    color:#ff6f61;
}

.unavailable-btn{
    background-color: #ff6f61;
    border-color: #ff6f61;
    outline: #ff6f61;
}

.project-item-btn-container{
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

@media screen and (max-aspect-ratio: 15/10) {
    #projectcotnainer{
        max-height: none;
        grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
        padding: 1.5rem 1rem;
    }

}

@media screen and (max-aspect-ratio: 10/10) {
    #projectcotnainer{
        max-height: none;
        grid-template-columns: repeat(1, 1fr); /* Three columns with equal width */
        padding: 1.5rem 1rem;
    }

    .project-item p{
        font-size: 3.5vw;
    }

    .project-item img{
        display: inline-block;
        width: 25vw;
        height: fit-content;
        padding: 2vw;
        margin-bottom: 1vh;
    }

    .project-item h4{
        font-size: 2.5vh;
        text-decoration: underline;
        margin: 0;
    }

    .availability p{
        font-size: 4vw;
    }

    .availability h6{
        font-size: 3.5vw;
    }

    .cornerLogo{
        height: 12.5vw !important;
    }
}